<template>
  <div>
    <Header />
    <div class="bg-gray-100 pb-14">
      <div class="bg-brand">
        <div class="block py-5 mx-3 lg:mx-16 lg:flex lg:justify-between">
          <div class="lg:hidden">
            <h1 class="font-semibold text-white">Delivering to</h1>
          </div>
          <div class="mt-4 lg:mt-0">
            <DeliveryAddress :newChangedAddress="newChangedAddress" :name='s' />
          </div>
          <div class="space-x-4 lg:flex">
            <div
              class="flex px-4 py-4 my-4 space-x-5 bg-white rounded shadow-md lg:my-0 lg:py-2"
            >
              <input
                @keydown.enter="sortwithSearch"
                @input="sortwithSearch"
                v-model="search"
                type="text"
                class="focus:outline-none"
                placeholder="Search Restaurants"
              />
              <div @click="sortwithSearch" class="flex justify-end flex-grow">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </div>
            </div>
            <div class="relative z-30 hidden">
              <div
                @click="showFilterDropdown"
                class="flex w-64 px-4 py-2 space-x-5 bg-white rounded shadow-md"
              >
                <p class="text-sm">Filter</p>
                <div class="flex justify-end flex-grow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                    />
                  </svg>
                </div>
              </div>
              <Dropdown
                @closeDropdwn="showFilterDropdown"
                :items="filterlist"
                v-show="FilterDropdown"
              />
            </div>
            <div class="relative z-30 hidden lg:block">
              <div
                @click="showRatingDropdown"
                class="flex px-4 py-2 space-x-2 bg-white rounded shadow-md"
              >
                <p class="text-sm">Sort By</p>
                <div class="flex justify-end flex-grow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </div>
              </div>
              <Dropdown
                @closeDropdwn="showRatingDropdown"
                :rating="true"
                @sortRating="sortRating"
                :items="ratinglist"
                v-show="RatingDropdown"
              />
            </div>
          </div>
        </div>
      </div>
      <BreadCrumb name="Resturants" />
      <!-- Instant Delivery -->
      <div v-if="Resturants.length">
        <div v-if="searchResult.length" class="mx-3 mt-8 lg:mx-16">
          <div>
            <h1 class="text-2xl font-semibold text-center">
              Filtered result {{ searchResult.length }} Resturant(s) found.
            </h1>
            <p class="text-sm text-center">
              Click here to
              <span class="cursor-pointer text-brand" @click="clearSearch"
                >Clear filter</span
              >
            </p>
          </div>
          <div class="mt-4">
            <ResturantsCards type="scheduled" :resturants="searchResult" />
          </div>
        </div>
        <div v-else class="mx-3 mt-8 lg:mx-16">
          <div class="mt-4">
            <ResturantsCards type="scheduled" :resturants="Resturants" />
          </div>
        </div>
      </div>
      <div v-else-if="message" class="mx-3 mt-8 lg:mx-16">
        <div class="flex justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-24 h-24"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>
        <h1 class="my-4 text-base font-semibold text-center">{{ message }}</h1>
      </div>
      <div v-else class="mx-3 mt-8 lg:mx-16">
        <SkeletonLoader />
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import BreadCrumb from "@/components/BreadCrumb.vue";
import DeliveryAddress from "@/components/DeliveryAddress.vue";
import ResturantsCards from "@/components/ResturantsCard.vue";
import Dropdown from "@/components/Dropdown.vue";

import SkeletonLoader from "@/components/SkeletonLoader.vue";
export default {
  components: {
    Header,
    Footer,
    DeliveryAddress,
    ResturantsCards,
    BreadCrumb,
    Dropdown,
    SkeletonLoader,
  },
  emits: ["getNewRestaurants"],
  data() {
    return {
      FilterDropdown: false,
      filterlist: [
        {
          name: "Food",
          url: "/food",
        },
        {
          name: "Cakes & pastries",
          url: "/cakes&pastries",
        },
        {
          name: "Drinks",
          url: "/drinks",
        },
      ],
      ratinglist: [
        {
          name: "2.5 - 3.5",
          start: 2.5,
          end: 3.5,
        },
        {
          name: "3.5 - 4.5",
          start: 3.5,
          end: 4.5,
        },
        {
          name: "4.5 - 5.0",
          start: 4.5,
          end: 5.0,
        },
      ],
      RatingDropdown: false,
      Resturants: [],
      search: "",
      searchResult: [],
      message: null,
      oldAddress: this.$store.getters.getAddress,
    };
  },
  computed: {
    newChangedAddress() {
      let newAddress = this.$store.getters.getAddress;
      if (this.oldAddress.address != newAddress.address) {
        this.oldAddress = newAddress;
        this.Resturants = [];
        this.getResturants();
      }
      console.log(newAddress,'newAddress')
      return newAddress;
    },
  },
  methods: {
    async getResturants() {
      //Get  Resturants from Api
      let newAddress = this.$store.getters.getAddress;
      let url =
        this.$store.state.base_url + "restaurant/fetch-nearby-restaurants";
      try {
        let res = await this.axios.post(url, newAddress);
        this.Resturants = res.data.sort(
          (a, b) => Number(b.isOpen) - Number(a.isOpen)
        );
        if (this.Resturants.length < 1) {
          this.showPreloader = false;
          this.message =
            "Keep in touch. We are working to be in your neighborhood soon!";
        }
      } catch (error) {
        this.showPreloader = false;
        this.message =
          "Keep in touch. We are working to be in your neighborhood soon!";
      }
    },
    sortBySpecificRestuarant(res) {
      const result = res.filter((data, i) => {
        if (data.publicURL === process.env.VUE_APP_DEFAULT_STORE) {
          return data;
        }
      });
      return result;
    },
    showFilterDropdown() {
      this.FilterDropdown = !this.FilterDropdown;
    },
    showRatingDropdown() {
      this.RatingDropdown = !this.RatingDropdown;
    },
    sortwithSearch() {
      this.clearSearch();
      setTimeout(() => {
        if (this.search) {
          this.searchResult = this.Resturants.filter((Resturant) =>
            Resturant.name.toLowerCase().includes(this.search.toLowerCase())
          );
        } else {
          this.searchResult = "";
        }
      }, 100);
    },
    clearSearch() {
      this.searchResult = [];
    },
    sortRating(data) {
      this.clearSearch();
      setTimeout(() => {
        let sortedRestaurant = this.Resturants.filter(
          (item) => item.rating >= data.start && item.rating <= data.end
        );
        this.searchResult = sortedRestaurant.length ? sortedRestaurant : [];
      }, 100);
    },
  },
  beforeMount() {
    this.getResturants();
  },
};
</script>

<style>
</style>